enifed('ember-views/system/event_dispatcher', ['exports', 'ember-utils', 'ember-debug', 'ember-metal', 'ember-runtime', 'ember-views/system/jquery', 'ember-views/system/action_manager', 'ember-environment', 'ember-views/compat/fallback-view-registry'], function (exports, _emberUtils, _emberDebug, _emberMetal, _emberRuntime, _jquery, _action_manager, _emberEnvironment, _fallbackViewRegistry) {
  'use strict';

  var ROOT_ELEMENT_CLASS = 'ember-application'; /**
                                                @module ember
                                                @submodule ember-views
                                                */

  var ROOT_ELEMENT_SELECTOR = '.' + ROOT_ELEMENT_CLASS;

  /**
    `Ember.EventDispatcher` handles delegating browser events to their
    corresponding `Ember.Views.` For example, when you click on a view,
    `Ember.EventDispatcher` ensures that that view's `mouseDown` method gets
    called.
  
    @class EventDispatcher
    @namespace Ember
    @private
    @extends Ember.Object
  */
  exports.default = _emberRuntime.Object.extend({

    /**
      The set of events names (and associated handler function names) to be setup
      and dispatched by the `EventDispatcher`. Modifications to this list can be done
      at setup time, generally via the `Ember.Application.customEvents` hash.
       To add new events to be listened to:
       ```javascript
      let App = Ember.Application.create({
        customEvents: {
          paste: 'paste'
        }
      });
      ```
       To prevent default events from being listened to:
       ```javascript
      let App = Ember.Application.create({
        customEvents: {
          mouseenter: null,
          mouseleave: null
        }
      });
      ```
      @property events
      @type Object
      @private
    */
    events: {
      touchstart: 'touchStart',
      touchmove: 'touchMove',
      touchend: 'touchEnd',
      touchcancel: 'touchCancel',
      keydown: 'keyDown',
      keyup: 'keyUp',
      keypress: 'keyPress',
      mousedown: 'mouseDown',
      mouseup: 'mouseUp',
      contextmenu: 'contextMenu',
      click: 'click',
      dblclick: 'doubleClick',
      mousemove: 'mouseMove',
      focusin: 'focusIn',
      focusout: 'focusOut',
      mouseenter: 'mouseEnter',
      mouseleave: 'mouseLeave',
      submit: 'submit',
      input: 'input',
      change: 'change',
      dragstart: 'dragStart',
      drag: 'drag',
      dragenter: 'dragEnter',
      dragleave: 'dragLeave',
      dragover: 'dragOver',
      drop: 'drop',
      dragend: 'dragEnd'
    },

    /**
      The root DOM element to which event listeners should be attached. Event
      listeners will be attached to the document unless this is overridden.
       Can be specified as a DOMElement or a selector string.
       The default body is a string since this may be evaluated before document.body
      exists in the DOM.
       @private
      @property rootElement
      @type DOMElement
      @default 'body'
    */
    rootElement: 'body',

    /**
      It enables events to be dispatched to the view's `eventManager.` When present,
      this object takes precedence over handling of events on the view itself.
       Note that most Ember applications do not use this feature. If your app also
      does not use it, consider setting this property to false to gain some performance
      improvement by allowing the EventDispatcher to skip the search for the
      `eventManager` on the view tree.
       ```javascript
      let EventDispatcher = Em.EventDispatcher.extend({
        events: {
            click       : 'click',
            focusin     : 'focusIn',
            focusout    : 'focusOut',
            change      : 'change'
        },
        canDispatchToEventManager: false
      });
      container.register('event_dispatcher:main', EventDispatcher);
      ```
       @property canDispatchToEventManager
      @type boolean
      @default false
      @since 1.7.0
      @deprecated
      @private
    */

    init: function () {
      this._super();
      (true && !(_emberEnvironment.environment.hasDOM) && (0, _emberDebug.assert)('EventDispatcher should never be instantiated in fastboot mode. Please report this as an Ember bug.', _emberEnvironment.environment.hasDOM));
      (true && !(!('canDispatchToEventManager' in this)) && (0, _emberDebug.deprecate)('`canDispatchToEventManager` has been deprecated in ' + this + '.', !('canDispatchToEventManager' in this), {
        id: 'ember-views.event-dispatcher.canDispatchToEventManager',
        until: '2.17.0'
      }));
    },


    /**
      Sets up event listeners for standard browser events.
       This will be called after the browser sends a `DOMContentReady` event. By
      default, it will set up all of the listeners on the document body. If you
      would like to register the listeners on a different element, set the event
      dispatcher's `root` property.
       @private
      @method setup
      @param addedEvents {Object}
    */
    setup: function (addedEvents, rootElement) {
      var event = void 0;
      var events = this._finalEvents = (0, _emberUtils.assign)({}, (0, _emberMetal.get)(this, 'events'), addedEvents);

      if ((0, _emberMetal.isNone)(rootElement)) {
        rootElement = (0, _emberMetal.get)(this, 'rootElement');
      } else {
        (0, _emberMetal.set)(this, 'rootElement', rootElement);
      }

      rootElement = (0, _jquery.default)(rootElement);

      (true && !(!rootElement.is(ROOT_ELEMENT_SELECTOR)) && (0, _emberDebug.assert)('You cannot use the same root element (' + (rootElement.selector || rootElement[0].tagName) + ') multiple times in an Ember.Application', !rootElement.is(ROOT_ELEMENT_SELECTOR)));
      (true && !(!rootElement.closest(ROOT_ELEMENT_SELECTOR).length) && (0, _emberDebug.assert)('You cannot make a new Ember.Application using a root element that is a descendent of an existing Ember.Application', !rootElement.closest(ROOT_ELEMENT_SELECTOR).length));
      (true && !(!rootElement.find(ROOT_ELEMENT_SELECTOR).length) && (0, _emberDebug.assert)('You cannot make a new Ember.Application using a root element that is an ancestor of an existing Ember.Application', !rootElement.find(ROOT_ELEMENT_SELECTOR).length));


      rootElement.addClass(ROOT_ELEMENT_CLASS);

      if (!rootElement.is(ROOT_ELEMENT_SELECTOR)) {
        throw new TypeError('Unable to add \'' + ROOT_ELEMENT_CLASS + '\' class to root element (' + (rootElement.selector || rootElement[0].tagName) + '). Make sure you set rootElement to the body or an element in the body.');
      }

      var viewRegistry = this._getViewRegistry();

      for (event in events) {
        if (events.hasOwnProperty(event)) {
          this.setupHandler(rootElement, event, events[event], viewRegistry);
        }
      }
    },


    /**
      Registers an event listener on the rootElement. If the given event is
      triggered, the provided event handler will be triggered on the target view.
       If the target view does not implement the event handler, or if the handler
      returns `false`, the parent view will be called. The event will continue to
      bubble to each successive parent view until it reaches the top.
       @private
      @method setupHandler
      @param {Element} rootElement
      @param {String} event the browser-originated event to listen to
      @param {String} eventName the name of the method to call on the view
      @param {Object} viewRegistry
    */
    setupHandler: function (rootElement, event, eventName, viewRegistry) {
      var self = this;

      if (eventName === null) {
        return;
      }

      rootElement.on(event + '.ember', '.ember-view', function (evt, triggeringManager) {
        var view = viewRegistry[this.id];
        var result = true;

        var manager = self.canDispatchToEventManager ? self._findNearestEventManager(view, eventName) : null;

        if (manager && manager !== triggeringManager) {
          result = self._dispatchEvent(manager, evt, eventName, view);
        } else if (view) {
          result = self._bubbleEvent(view, evt, eventName);
        }

        return result;
      });

      rootElement.on(event + '.ember', '[data-ember-action]', function (evt) {
        var attributes = evt.currentTarget.attributes;
        var handledActions = [];

        for (var i = 0; i < attributes.length; i++) {
          var attr = attributes.item(i);
          var attrName = attr.name;

          if (attrName.lastIndexOf('data-ember-action-', 0) !== -1) {
            var action = _action_manager.default.registeredActions[attr.value];

            // We have to check for action here since in some cases, jQuery will trigger
            // an event on `removeChild` (i.e. focusout) after we've already torn down the
            // action handlers for the view.
            if (action && action.eventName === eventName && handledActions.indexOf(action) === -1) {
              action.handler(evt);
              // Action handlers can mutate state which in turn creates new attributes on the element.
              // This effect could cause the `data-ember-action` attribute to shift down and be invoked twice.
              // To avoid this, we keep track of which actions have been handled.
              handledActions.push(action);
            }
          }
        }
      });
    },
    _getViewRegistry: function () {
      var owner = (0, _emberUtils.getOwner)(this);
      var viewRegistry = owner && owner.lookup('-view-registry:main') || _fallbackViewRegistry.default;

      return viewRegistry;
    },
    _findNearestEventManager: function (view, eventName) {
      var manager = null;

      while (view) {
        manager = (0, _emberMetal.get)(view, 'eventManager');
        if (manager && manager[eventName]) {
          break;
        }

        view = (0, _emberMetal.get)(view, 'parentView');
      }

      return manager;
    },
    _dispatchEvent: function (object, evt, eventName, view) {
      var result = true;

      var handler = object[eventName];
      if (typeof handler === 'function') {
        result = (0, _emberMetal.run)(object, handler, evt, view);
        // Do not preventDefault in eventManagers.
        evt.stopPropagation();
      } else {
        result = this._bubbleEvent(view, evt, eventName);
      }

      return result;
    },
    _bubbleEvent: function (view, evt, eventName) {
      return view.handleEvent(eventName, evt);
    },
    destroy: function () {
      var rootElement = (0, _emberMetal.get)(this, 'rootElement');
      (0, _jquery.default)(rootElement).off('.ember', '**').removeClass(ROOT_ELEMENT_CLASS);
      return this._super.apply(this, arguments);
    },
    toString: function () {
      return '(EventDispatcher)';
    }
  });
});